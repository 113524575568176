.tabs {
  margin: 10px;
  max-width: 100%;
  overflow: hidden;
}

.tabsContainer {
  /* display: flex; */
  /* width: 1020px; */
  max-width: 100%;
  /* align-items: center; */
}

.mapTabs {
  margin: 10px;
  width: 100%;
  max-width: 1000px;
  overflow: hidden;
}

.space {
  margin-bottom: 20px;
}


.section > p {
  max-width: unset;
  width: 100% !important;
}



@media only screen and (min-width: 769px) {

  .tabs {
    margin: 0;
    /* max-width: 1020px; */
  }

  .articles {
    display: grid;
    grid-gap: 20px 30px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    box-sizing: border-box;
    overflow: hidden;
  }

}