.scroller {
  /* background-color: pink; */
  width: 100%;
  /* max-width: 1000px;
  display: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; */

  /* height: 200px; */
}

.scrollerContainer {
  width: 100%;
  max-width: 1100px;
}

.header {
  max-width: 1020px;
  margin: 0 10px 0 10px;
  border-bottom: 2px solid var(--main2);
  /* padding: 10px 0; */
  /* margin-bottom: 55px; */
  display: flex;
  justify-content: space-between;
}

.tabs {
  margin-bottom: 25px;  
  max-width: 1020px;
}

.descriptorWrapper {
  display: flex;
  width: 100%;
  /* background-color: pink; */
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.descriptor {
  /* background-color: white; */
  max-width: 1020px;
  align-items: center;
  justify-content: center;
  display: flex;
}


/* DESKTOP */
@media only screen and (min-width: 769px) {

}




/* Header Only */
@media only screen and (min-width: 1035px) {
  .header, .tabs {
    margin-left: 40px;
  }
  
}
