.wrapper {
  width: 100%;
  /* background-color: pink; */
  max-width: 1000px;
}

.blockquote {
  margin: 20px 10px;
  max-width: 550px;
  background: var(--main8);
  border-left: 10px solid var(--main4);
  /* margin: 1.5em 10px; */
  padding: 0.5em 30px 30px 10px;
  quotes: "\201C""\201D""\2018""\2019";
  width: 100%;
  position: relative;
}

.blockquote p {
  display: inline;
}

.blockquote::before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}

.blockquote::after {
  color: #ccc;

  position: absolute;
  content: close-quote;
  font-size: 4em;
  bottom: 0;
  right: 0;
  margin-right: 0.2em;
  margin-bottom: -0.6em;
}



/* DESKTOP */
@media only screen and (min-width: 1000px) {
  .blockquote {
    margin: 20px 0;
  }
}
