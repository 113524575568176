.arrow {
  userSelect: none;
  cursor: pointer;
  background-color: var(--grey2);
  color: var(--grey10);
  border: none;
  border-radius: 40px;
  height: 44px;
  width: 44px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition : all 0.4s ease;
}

.invisible {
  opacity: 0;
}


/* DESKTOP */
@media only screen and (min-width: 769px) {
  .arrow {
    height: 24px;
    width: 24px;
  }
}
