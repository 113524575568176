.header {
  background-color: var(--grey0);
  /* background-color: pink; */
  width: 100%;
  /* max-width: 1300px; */
  min-height: 450px;
  /* z-index: 100; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  backdrop-filter: blur(25px);
  -webkit-backdrop-filter: blur(10px);
  opacity: 0.85;
  padding: 10px 0 65px 0;
  margin-top: 30px;
  /* box-sizing: border-box; */
}


.altHeader {
  max-width: 1020px;
  margin: 0 10px 0 10px;
  border-bottom: 2px solid var(--main2);
  /* padding: 10px 0; */
  /* margin-bottom: 55px; */
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.title {
  height: 420px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subtitle {
  margin: 0 20px;
  /* width: 1000px; */
  max-width: 1000px;
  text-align: center;
}

.iconContainer {
  max-width: 46px;
  margin: 60px 0 40px 0;
}

.children {
  margin: 60px 10px;
  z-index: 1001;
  min-width: 100%;
  max-width: 100%;
  /* display: flex; */
  /* justify-content: center; */
   /* margin: auto; */
}


.titles {
  z-index: 2;
  padding: 0 20px;
  max-width: 450px;
  position: absolute;
}

.titles > h1 {
  text-align: center;
}



.image {
  z-index: 1;
  width: unset;
  height: 100%;
  width: auto;
  /* min-height: 100%; */
}

.lightText {
  margin: 10px;
  color: var(--grey0);
  white-space: pre-wrap;
}

.darkText {
  margin: 10px;
  color: var(--main1);
  white-space: pre-wrap;
}



/* IPAD */
@media only screen and (min-width: 577px) {

  .header {
    min-height: 450px;
  }

}





/* DESKTOP */
@media only screen and (min-width: 769px) {
  .image {
    width: 100%;
    height: auto;
    min-height: 100%;
  }

  .header {
    /* margin-top: 450px; */
  }



}
