.header {
  /* background-color: var(--main7); */
  width: 100%;
  max-width: 1300px;
  height: 450px;
  position: fixed;
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  /* box-sizing: border-box; */
}

.bg {
  background-color: #F4F3EE;
}

.titles {
  z-index: 2;
  padding: 0 20px;
  max-width: 450px;
  position: absolute;
}

.titles > h1 {
  text-align: center;
}


.wideTitles {
  max-width: 650px;
}

.imageContainer {
  z-index: 1;
  position: relative;
  /* height: 100%; */
  /* width: 100%; */
  /* background-color: hsl(187, 100%, 90%); */
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 500px; */
  /* overflow: hidden; */
}

.image {
  z-index: 1;
  opacity: 0.85;
  width: unset;
  /* height: 100%; */
  /* width: auto; */
  /* min-height: 100%; */
}

.lightText {
  color: var(--grey0);
  white-space: pre-wrap;

}

.wide {
  height: 400px;
  max-width: unset;
  margin-top: 0px;
  margin-bottom: 10px;
}


/* IPAD */
@media only screen and (min-width: 577px) {

  .wide {
    max-width: unset;
  }

  .header {
    height: 450px;
  }


}





/* DESKTOP */
@media only screen and (min-width: 769px) {
  .image {
    width: 100%;
    height: auto;
    min-height: 100%;
  }

  .header {
    margin-top: 20px;
  }

  .wide {
    margin-top: 0px;
  }

}
