.header {
  /* background-color: var(--main7); */
  width: 100%;
  max-width: 1000px;
  /* height: 360px; */
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
  /* box-sizing: border-box; */
}

.titles {
  z-index: 2;
  padding: 0 20px;
  max-width: 450px;
  /* position: absolute; */
}

.h1 {
  margin: 10px 0;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: -0.3px;
  max-width: 1000px;
  width: 100%;
  color: var(--main3);
}

.h2 {
  font-weight: 700;
  color: var(--grey10);
  font-size: 18px;
  letter-spacing: -0.3px;
}

.headerSection {
  align-items: center;
}

.subtitle {
  margin: 0 10px;
  max-width: 1000px;
  width: 100%;
}

.wideTitles {
  max-width: 650px;
}

.imageContainer {
  z-index: 1;
  position: relative;
  height: 100%;
  width: 100%;
  height: 340px;
  margin: 20px;
  overflow: hidden;
  /* background-color: #000; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 500px; */
  /* overflow: hidden; */
}

.image {
  z-index: 1;
  width: unset;
  height: 100%;
  width: auto;
  /* min-height: 100%; */
}

.lightText {
  color: var(--grey10);
  white-space: pre-wrap;

}

.wide {
  /* height: 400px; */
  max-width: unset;
  margin-top: 0px;
  margin-bottom: 10px;
}


/* IPAD */
@media only screen and (min-width: 577px) {

  .wide {
    max-width: unset;
  }

  .header {
    /* height: 360px; */
  }


}





/* DESKTOP */
@media only screen and (min-width: 769px) {
  .image {
    width: 100%;
    height: auto;
    min-height: 100%;
  }

  .header {
    margin-top: 20px;
  }

  .wide {
    margin-top: 0px;
  }

}
