.wrapper {
  box-sizing: border-box;
  margin-top: 48px;
  width: 100%;
  height: calc(100% - 50px);
}

.iframe {
  width: 100%;
  height: 100%;
}



/* Tablet and above */
@media only screen and (min-width: 769px) {
  .wrapper {
    box-sizing: border-box;
    margin-top: 60px;
    width: 100%;
    height: calc(100% - 65px);
  }
}
