.h1 {
  font-size: 24px;
  line-height: 34px;
  letter-spacing: -0.7px;
  color: var(--grey0);
}

.h2 {
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0px;
  color: var(--grey0);
}

.linkText > a {
  color: white;
  text-decoration: underline;
}

.leafletContainer {
  height: 100%;
  z-index: 1;
}

.mapContainer {
  margin: 48px 0 0 0;
  width: 100%;
  height: calc(100% - 48px);
  /* border-radius: 10px; */
  overflow: hidden;
  position: absolute;
  z-index: 1;
}

.popup {
  position: absolute;
  background-color: #fff;
  color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1001;
  right: 0px;
  top: 0px;
  bottom: 0px;
  border-radius: 2px 0 0 2px;
  transform: translate(0);
  transition: all 0.2s ease;
  font-size: 16px;
  overflow: auto;
  /* padding: 20px; */
}

.body {
  padding: 20px;
}

.popupHidden {
  transform: translate(120%);
  transition: all 0.2s ease;
}

.popupHeader {
  font-size: 11px !important;
  letter-spacing: -0.4px;
  font-weight: 700;
  margin: 0;
  color: var(--grey6a);
}

.plotContainer {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pieLabel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.pieText {
  margin: 0;
  color: var(--grey0);
  font-size: 18px;
}

.pieYear {
  color: var(--grey4) !important;
}

.sidebar {
  background-color: white;
  position: fixed;
  right: 0px;
  top: 48px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 48px);
  width: 100%;
  z-index: 1001;
  border-radius: 5px;
  transform : translate(0);
  transition : all 0.4s ease;
  /* overflow-y:visible; */
  /* overflow: auto; */
}

.main {
  /* padding: 20px; */
  overflow: auto;
}

.sidebarHidden {
  transform: translate(100%);
}

.option {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.header {
  width: 100%;
  background-color: var(--main3);
  padding: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.pHeader {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px;
  background-color: var(--main3);
}

.pTitle {
  color: var(--grey0);
  font-size: 18px;
  text-align: left;
  max-width: 130px;
}

.lhs {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 0 0 0 20px;
  height: 100%;
  overflow-wrap: break-word;
}

.rhs {
  width: 200px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.closer {
  position: absolute;
  z-index: 5;
  background-color: var(--main3);
  color: var(--grey0);
  border-color: var(--grey0);
  border-radius: 20px;
}

.closerSidebar {
  z-index: 5;
  background-color: var(--main3);
  color: var(--grey0);
  border-color: var(--grey0);
  border-radius: 20px;
}

.sectionTitle {
  font-weight: 700;
  font-size: 14px;
  color: var(--grey8);
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.split {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slider {
  /* margin-top: 20px; */
  margin: 20px 0 0 0;
  width: 100%;
}

.mapSection {
  margin: 20px 0 0 0;
  width: 100%;
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%; */
}

.label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  margin-bottom: 4px;
}

.layers {
  padding-bottom: 40px;
}

.partition {
  padding: 30px;
  margin: 0;
  width: 100%;
}

.legend {
  background-color: var(--main5);
  padding: 30px;
}


.legendEntries {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.legendEntry {
  display: flex;
  align-items: center;
}

.legendEntry > * {
  margin-right: 5px;
}

.legendTitle {
  margin: 0;
}

.filterButton {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1000;
}

.sidebarToggle {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -30px;
  top: 50%;
  background-color: var(--grey0);
  height: 50px;
  width: 30px;
  border-radius: 20px 0 0 20px;
  margin: 10px 0;
  transition : all 0.2s ease;
  cursor: pointer;
  visibility: hidden;
  border: none;
}

.sidebarToggle:hover {
  height: 70px;
  margin: 0;
  transition : all 0.2s ease;
  background-color: var(--grey1);
}

.sidebarToggle:focus {
  /* box-shadow: none; */
  border-left: 3px solid var(--focus);
  border-top: 3px solid var(--focus);
  border-bottom: 3px solid var(--focus);

}


.toggleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(0deg);
  transition : all 0.2s ease;
}

.reverseArrow {
  transform: rotate(180deg);
}


.areaLabel {
  color: var(--grey7);
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
  font-weight: 700;
  letter-spacing: 0.3px;
  font-size: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
}

.labelHigh {
  color: pink;
}

.tooltip {
  /* width: 60px; */
  /* height: 40px; */
  background-color: var(--grey0);
  color: var(--grey10);
  box-shadow: none !important;
  outline: none !important;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 5px 10px;
  opacity: 0.95;
}

.tooltip > p {
  line-height: 16px;
}

.tooltip:focus, .tooltip:hover {
  box-shadow: none !important;
  outline: none !important;
}



.elem {
  margin: 0px 10px;
}

.val {
  font-weight: 300;
  font-size: 18px;
}

.baseLayers {
  z-index: 200;
}

.topLayers {
  z-index: 600;
}


@media only screen and (min-width: 577px) {
  /* IPAD */

  .sidebar {
    width: 380px;
  }

  .main {
    /* padding: 0 30px; */
  }

  .header {
    height: unset;
    padding: 40px;
  }


  .pHeader {
    padding: 10px;
  }

  .pTitle {
    font-size: 22px;
  }

  .popup {
    width: 380px;
  }

  .sidebarToggle {
    visibility: visible;
  }

  .sidebarCloser {
    visibility: hidden;
    display: none;
  }

  .areaLabel {
    font-size: 14px;

  }

}



@media only screen and (min-width: 769px) {
  /* DESKTOP */


  .filterButton {
    visibility: hidden;
  }

  .sidebar {
    top: 60px;
    width: 450px;
    height: calc(100% - 60px);
  }

  .popup {
    width: 450px;
  }

  .main {
    /* padding: 0 50px; */
  }

  .header {
    width: 100%;
    /* background-color: rgba(215, 215, 215, 0.25); */
    padding: 39px;
  }


  .pHeader {
    padding: 10px;
  }

  .mapContainer {
    height: calc(100% - 60px);
    margin: 60px 0 0 0;

  }

  .pTitle {
    font-size: 22px;
    max-width: 200px;
  }

}
