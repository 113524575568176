.logos {
  /* background-color: pink; */
  width: 100%;
  /* height: 200px; */
  display: flex;
  flex-direction: column;
  gap: 80px;
  margin-top: 20px;
  align-items: center;
  flex: 1;
}

.logo {
  /* background-color: lightblue; */
  /* width: auto; */
  flex: 1;
  /* height: 20px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.image {
  max-width: 400px;
  margin-bottom: 20px;
}


/* DESKTOP */
@media only screen and (min-width: 1000px) {
  .logos {
    flex-direction: row;
    gap: 70px;
  }

  .logo {
    height: 160px;
  }

  .middle {
    flex: unset;
    width: 480px;
  }

  .image {
    max-width: unset;
  }
}
