.h2 {
  max-width: 1000px;
  font-size: 26px;
  line-height: 42px;
  letter-spacing: -0.4px;
  margin-top: 30px;
  text-align: left;
}

.h3 {
  margin: 30px 0 0 0;
  font-size: 20px;
  font-weight: 700;
  margin-top: 20px;
  text-align: left;
}

.h4 {
  font-weight: 400;
  max-width: 1000px;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.6px;
  margin-top: 30px;
  text-align: left;

}


.h1, .h2, .h3, .h4, .h5, .h6, .ul {
  max-width: 1000px;
  width: 100%;
}

.ul {
  /* margin-left: 80px; */
}


.table {
  margin: 0 10px;
  max-width: 1000px;
}

.para {
  max-width: 1000px;
}


/* DESKTOP */
@media only screen and (min-width: 769px) {



  .header {
    padding: 0;
  }

  main {
    margin-top: 60px;
    /* margin-top: 200px; */

  }

}
