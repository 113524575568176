.modal {
  background-color: var(--grey0);
  position: fixed;
  width: 100%;
  /* height: 130px; */
  z-index: 20;
  bottom: 0;
  padding: 20px;
  box-shadow: 0 -5px 20px var(--light-shadow);
}

.bio {
  line-height: 16px;
  margin: 2px 0;
}

.buttons {
  display: flex;
  gap: 15px;
  padding-top: 10px;
}