.body {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.p {
  width: 100%;
}

.wide {
  max-width: 1000px;
  width: 100%;
}