.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 40px 0;
  max-width: 1000px;
}

@media only screen and (min-width: 700px) {
  .wrapper {
    /* min-height: 450px; */
    flex-direction: row;
  }
}
