.map {
  height: 500px;
  min-width: 100%;
  border-radius: 4px;
}


.mapLabel {
  position: absolute;
  top: 0px;
  z-index: 499;
  background-image: linear-gradient(to right,  hsla(0, 0%, 0%, 0.6), hsla(0, 0%, 0%, 0.5));
  color: var(--grey0);
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  font-size: 20px;
  cursor: pointer;
}

.clickLayer {
  position: absolute;
  z-index: 500;
  background-color: none;
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
