.modalBackground {
  background-color: var(--transparent-black);
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 1004;
}

.modal {
  background-color: var(--grey0);
  /* padding: 20px; */
  height: calc(100% - 68px);
  margin: 10px;
  border-radius: 5px;
  /* overflow: auto; */
}

.header {
  color: var(--grey0);
  font-size: 20px;
  margin-top: 0;
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-bottom: 20px; */
  background-color: var(--main3);
  padding: 40px;
  height: 100px;
  border-radius: 5px 5px 0 0;
}

.icon {
  padding: 0;
  cursor: pointer;
  /* margin-left: 20px; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--transparent);
 border: none;
 /* padding: 15px 32px; */
 text-decoration: none;
 font-size: 16px;
}



.p {
  line-height: 25px;
}


.body {
  max-height: calc(100% - 100px);
  padding: 20px;
  overflow: auto;
}

@media only screen and (min-width: 577px) {



}



@media only screen and (min-width: 769px) {

  .body {
    padding: 40px;
    overflow: auto;
  }

  .modalBackground {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modal {
    /* padding: 40px; */
    height: auto;
    /* min-height: 300px; */
    /* max-height: 600px; */
    min-width: 300px;
    max-width: 500px;
  }

}
