.nav {
  z-index: 1000;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  box-sizing: border-box;
  position: fixed;
  background-color: var(--main1);
  /* height: 40px; */
}

.links {
  box-sizing: border-box;
  position: relative;
  list-style-type: none;
  /* padding: 20px; */
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  display: none;
  width: 100%;
  height: 100%;
  background-color: var(--main1);
  padding: 0px;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 60px;
  font-weight: 400;
  font-size: 16px;
  margin: 0;

}

.linkTop {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.link > a, .linkTop > a {
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: var(--grey0);
  text-decoration: none;
  padding: 0 20px;
  height: 60px;
  min-height: 60px;
  margin: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* flex-direction: column; */
  /* padding: 20px 100px; */
  /* padding: 20px; */

  flex: 1;
}


.link > a:hover {
  color: var(--main4)
}



.activeLink, .activeLink > a {
  /* text-decoration: underline; */
  background-color: var(--main2);
  color: var(--grey10);
}

.activeLinkMulti, .activeLinkMulti > a {
  background-color: var(--main2);
  color: var(--grey10);
}



.logoContainer {
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  margin: 0 30px;
  display: none;
}

.logo {
  width: 100%;
  width: 127px;
  /* height: 46px; */
}

.logoContainer > picture {
  display: flex;
  width: 100%;
}



.menuBtnContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  background-color: inherit;
}

.menuBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main1);
  border: none;
  color: white;
  padding: 13px 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}


.responsive {
  display: block;
  position: relative;
}


@media only screen and (min-width: 577px) {
  .logoContainer {
   margin: 0 10px;
  }
}



@media only screen and (min-width: 769px) {

  .logoContainer {
    display: none;
  }

  .nav {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 60px;
    background-color: var(--main1);
  }

  .menuBtn {
    display: none;
    flex-grow: 0;
  }

  .menuBtnContainer {
    width: 0;
  }

  .links {
    flex-direction: row;
    visibility: visible;
    justify-content: center;
    display: flex;
    width: 100%;
    height: 100%;
    flex-grow: 1;
  }

  .link {
    padding: 30px 0;
  }

  .link > a {
    width: unset;
  }


  .activeLinkMulti {
    background-color: var(--main2);
  }



}


@media only screen and (min-width: 928px) {
  .logoContainer {
    display: flex;
  }

}



@media only screen and (min-width: 1230px) {
  .logoContainer {
    margin: 0 30px;
  }

}