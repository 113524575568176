.container {
  /* padding: 10px; */
  cursor: pointer;
  background-color: var(--grey0);
  border: none;
  color: var(--grey10);
  /* text-align: center; */
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  /* padding: 10px 10px; */
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 0 80px 0;
  overflow: hidden;
}






.right {
  min-height: 170px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.text {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
}




.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.subTitle {
  /* white-space: nowrap; */
  /* margin: 10px 0; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.para {
  line-height: 20px;
  margin: 0;
  padding: 0;
}


.imageContainer {
  max-height: 280px;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}







/* Tablet and above */
@media only screen and (min-width: 769px) {

  .container {
    /* margin: 20px 10px; */
  }

  /* .imageContainer {
    min-width: 200px;
    width: 200px;
    height: 200px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  } */

  /* .image {
    height: 200px;
    width: auto;
  } */




  
  .imageContainer img {
    transition: transform .5s ease;
  }
  
  .imageContainer:hover img {
    transform: scale(1.1);
  }


  .right {
    /* margin-left: 50px; */
  }


  .text {
    /* max-width: 300px */
    /* padding: 0 10px; */
  }

  .button {
    /* margin-left: 10px; */
  }

  .textLargeImage {
    justify-content: flex-start;
  }

}






