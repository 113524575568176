.card {
  background-color: var(--accent-high);
  height: 210px;
  width: 155px;
  display: inline-block;
  margin: 0 10px;
  userSelect: none;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}


.description {
  padding: 0 10px 10px 10px;
}

.area {
  font-size: 16px;
  letter-spacing: -0.8px;
  line-height: 22px;
  margin-top: 0;
  color: #0A0A0A;
}

.tag {
  margin-top: 10px;
  background-color: var(--accent-alpha-c);
  border-radius: 4px;
  padding: 4px 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.la {
  font-size: 13px;
  /* letter-spacing: 2px; */
  line-height: 13px;
  font-weight: 700;
  margin-top: 0;
  /* color: var(--grey7); */
}

.value {
  font-size: 13px;
  letter-spacing: -0.5px;
  margin: 0;
  color: #0A0A0A;
}


.plotContainer {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pieLabel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 80px;
}

.text {
  font-weight: 700;
  font-size: 13px;
  letter-spacing: -0.5px;
  color: var(--grey10);
  margin-top: 0;

}



.low {
  background-color: var(--accent-low);
}

.med {
  background-color: var(--accent-medium);
}

.high {
  background-color: var(--accent-high);
}

.veryhigh {
  background-color: var(--accent-vhigh);
}