.directions {
  margin: 80px 0;
  max-width: 1000px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.left {
  display: none;
}

.right {
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn {
  margin: 0 10px;
}



/* IPAD */
@media only screen and (min-width: 577px) {

  .directions {
    width: 100%;
  }

  .left {
    display: flex;
  }


}





/* DESKTOP */
@media only screen and (min-width: 769px) {
  .image {
    width: 100%;
    height: auto;
    min-height: 100%;
  }

  .header {
    margin-top: 20px;
  }

  .wide {
    margin-top: 0px;
  }

}
