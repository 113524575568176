.container {
  background-color: var(--grey0);
  /* background-color: pink; */
  border-left: solid 6px var(--main3);
  /* border: none; */
  color: var(--grey10);
  /* text-align: center; */
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  /* padding: 10px 10px; */
  width: 100%;
  margin: 10px;
  padding: 10px 0;
  max-width: 950px;
}


.text {
  /* height: 100px; */
  width: 100%;
  padding: 0 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-around;
  text-align: left;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* -webkit-line-clamp: 1; */
  /* -webkit-box-orient: vertical; */
  font-size: 16px;
}

.subTitle {
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* -webkit-line-clamp: 3; */
  /* -webkit-box-orient: vertical; */
}

.para {
  line-height: 22px;
}

/* SMALL PHONE */
@media only screen and (min-width: 400px) {
  .listDualContainer {
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  }
}
