.container {
  cursor: pointer;
  background-color: var(--grey0);
  border: none;
  color: var(--grey10);
  /* text-align: center; */
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  /* padding: 10px 10px; */
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 0 80px 0
}

.containerNonInteractive {
  background-color: var(--grey0);
  border: none;
  color: var(--grey10);
  /* text-align: center; */
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  /* padding: 10px 10px; */
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 0 80px 0
}


.right {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.text {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-align: left;
}




.title {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: 40px;
}

.subTitle {
  /* white-space: nowrap; */
  margin: 10px 0;
  overflow: visibile;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.para {
  line-height: 22px;
  margin: 0;
  padding: 0;
  overflow: hidden;

}










/* Tablet and above */
@media only screen and (min-width: 769px) {

  .container {
    flex-direction: row;
    margin: 20px 10px;
  }

  .containerNonInteractive {
    flex-direction: row;
    margin: 20px 10px;
  }

  .imageContainer {
    min-width: 200px;
    width: 200px;
    height: 200px;
    background-color: var(--grey2);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    /* padding: 10px; */
  }


  .imageContainerNonInteractive {
    min-width: 200px;
    width: 200px;
    height: 200px;
    background-color: var(--grey2);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    /* padding: 10px; */
  }

  .image {
    height: 200px;
    width: auto;
  }


  .imageContainerLarge {
    min-width: 400px;
    width: 400px;
    height: auto;

  }

  .imageLarge {
    height: 300px;
    width: auto;
  }

  
  .imageContainer img {
    transition: transform .5s ease;
  }
  
  .imageContainer:hover img {
    transform: scale(1.1);
  }


  .right {
    margin-left: 50px;
  }


  .text {
    padding: 0 10px;
  }

  .button {
    margin-left: 10px;
  }

  .textLargeImage {
    justify-content: flex-start;
  }

}
