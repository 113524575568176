.imgContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.img {
  max-width: 500px;
}

.section > p {
  max-width: unset;
  width: 100% !important;
}

.article {
  max-width: 600px;
  padding: 10px;
  height: 300px;
}


.contactInfo {
  margin-top: 15px;
  display: block;
}

/* Tablet and above */
@media only screen and (min-width: 769px) {
  .articles {
    display: grid;
    grid-gap: 20px 30px;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
    box-sizing: border-box;
    overflow: hidden;
  }
}