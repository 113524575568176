@import '~antd/dist/antd.css';


/* CSS Reset */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-rendering: optimizeLegibility; */
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');
html, body, .app {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  color: var(--grey10);
  box-sizing: border-box;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

main {
  margin-top: 46px;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  background-color: var(grey0);
}

section {
  box-sizing: border-box;
  max-width: 1000px;
  width: calc(100% - 20px);
  margin: 60px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

strong {
  font-weight: 700;
}


/* Standard Colors */
:root {

/* Greys */
  --grey0: hsl(300, 0%, 100%);
  --grey1: hsl(300, 0%, 97%);
  --grey2: hsl(300, 3%, 94%);
  --grey3: hsl(300, 0%, 85%);
  --grey4: hsl(300, 0%, 78%);
  --grey5: hsl(300, 0%, 75%);
  --grey6: hsl(300, 0%, 67%);
  --grey6a: hsl(300, 0%, 50%);
  --grey7: hsl(300, 0%, 35%);
  --grey8: hsl(300, 0%, 30%);
  --grey9: hsl(300, 0%, 26%);
  --grey10: #383838;

/* Main Colors */
  --main6: hsl(213, 91%, 27%);
  --main2: hsl(192, 100%, 44%);
  --main4: hsl(195, 80%, 40%);
  --main1: hsl(214, 92%, 24%);
  --main3: hsl(214, 84%, 32%);
  --main5: hsl(211, 100%, 89%);
  --main7: hsl(197, 69%, 89%);
  --main8: hsl(197, 69%, 98%);
  --main9: hsl(214, 92%, 15%);
  --main10: hsl(214, 92%, 40%);
  --main11: hsl(192, 100%, 35%);

  /* Main Colors */
    /* --main6: hsl(195, 86%, 29%);
    --main2: hsl(195, 87%, 31%);
    --main4: hsl(195, 80%, 40%);
    --main1: hsl(199, 76%, 44%);
    --main3: hsl(196, 76%, 50%);
    --main5: hsl(198, 80%, 87%);
    --main7: hsl(197, 69%, 89%);
    --main8: hsl(197, 69%, 98%); */


  --focus: hsl(194, 100%, 50%);

  --accent1: hsl(202, 100%, 78%);
  --accent2: hsl(208, 100%, 54%);


/* Accent Colors */
  --accent-low: hsl(86, 74%, 54%);
  --accent-medium: hsl(53, 100%, 59%);
  --accent-high: hsl(10, 96%, 60%);
  --accent-vhigh: hsl(10, 91%, 58%);

  --accent-alpha-a: hsla(0, 0%, 100%, 0.3);
  --accent-alpha-b: hsla(0, 0%, 100%, 0.9);
  --accent-alpha-c: hsla(0, 0%, 100%, 0.4);


  --grad-1: hsl(347, 100%, 37%);
  --grad-2: hsl(7, 87%, 53%);
  --grad-3: hsl(34, 100%, 57%);
  --grad-4: hsl(54, 100%, 78%);
  --grad-5: grey;


  --accent-medium-low: hsl(36, 100%, 59%);
  --accent-wtw: hsl(0, 83%, 74%);

  --transparent-black: hsla(0, 0%, 0%, 0.3);
  --transparent: hsla(0, 0%, 0%, 0);
  --light-shadow: hsla(300, 0%, 67%, 0.6);


}


a {
  color: var(--main10);
}




*:focus:not(.ant-switch, .ant-slider-handle) {
  outline: none;
  box-shadow: 0 0 0 3px var(--focus);
  border-radius: 2px;
}



h1 {
  font-size: 50px;
  font-weight: 700;
  letter-spacing: -2.5px;
  line-height: 70px;
  color: var(--grey10);
}

h2 {
  margin-top: 30px;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0px;
  color: var(--main6);
}

h3 {
  margin: 10px 0;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: -0.3px;
  color: var(--main3);
}

h4 {
  /* margin: 10px 0; */
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.3px;
  color: var(--main6);
}


h5 {
  font-size: 13px;
  margin: 0;
  margin-top: -20px;
  padding: 10px 0;

}


img {
  width: 100%;
}

p {
  padding: 0 10px;
  line-height: 30px;
  width: 100%;
  margin: 10px 0;
}

ul {
  padding: 0 10px;
}

li {
  margin-left: 40px;
}

sup, sub {
  font-size: 70%;
}


blockquote {
  max-width: 550px;
  background: var(--main8);
  border-left: 10px solid var(--main4);
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
  width: 100%;
}

blockquote p {
  display: inline;
}

blockquote:before {
  color: #ccc;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}





em {
  font-style: italic;
}


.lightText {
  color: var(--grey0);
  white-space: pre-wrap;
}

.lightweight {
  font-weight: 400;
}

td, th {
  border: 1px solid #dbdbdb;
  padding: 8px;
}

/* tr:nth-child(even) {
  background-color: #f2f2f2;
} */

/* tr:hover {
  background-color: #ddd;
} */

th {
  font-weight: 700;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: var(--main1);
  color: white;
}

table {
  margin: 20px 0;
}

.body {
  display: flex;
  width: 100%;
  background-color: var(--grey0);
  /* align-items: center; */
}

.body-container {
  width: 100%;
  margin-top: 46px;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.body-text {
  width: 100%;
  max-width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rhs {
  background-color: pink;
  position: -webkit-sticky;
  position: sticky;
  margin-top: 160px;
  margin-right: -350px;
  visibility: none;
  display: none;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}


.section-header {
  max-width: 1020px;
  /* margin: 0 10px 0 10px; */
  border-bottom: 2px solid var(--main2);
  padding: 10px 0;
  /* margin-bottom: 20px; */
  display: flex;
  align-items: baseline;

}

.section-header-short {
  max-width: 1020px;
  border-bottom: 1px solid var(--main2);
  padding: 10px 0;
  margin-bottom: 0}

.section-text {
  max-width: 750px;
}

.markdown-wrapper {
  text-align: left;
}

.recharts-label {
  font-size: 14px;
}

.ant-btn {
  border-radius: 0;
  border-width: 2px;
  /* font-size: 16px; */
  color: var(--main1);
  border-color: var(--main1);
  /* background: var(--main1); */
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn:hover {
  /* border-color: var(--main4); */
  /* color: var(--main2); */
}


.ant-btn-primary {
  background-color: var(--main1);
  color: var(--grey0);
}

.ant-btn-primary:hover {
  background-color: var(--main3);
  border-color: var(--main3);
  color: var(--grey0);
}


.ant-tabs-content-holder {
  padding: 0 !important;
  margin: 0 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--main10);
}


.react-horizontal-scrolling-menu--arrow-left, .react-horizontal-scrolling-menu--arrow-right {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* position: absolute; */
  z-index: 10;
  margin-top: 85px;
  width: 44px;
  height: 44px;
  justify-content: center;
}


.react-horizontal-scrolling-menu--arrow-right {
  /* right: 0px; */
}

.recharts-tooltip-wrapper {
  outline: none !important;
  box-shadow: none !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}


footer {
  flex-shrink: 0;
  box-sizing: border-box;
  width: 100%;
  background-color: var(--main1);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 100px;
}

footer > * {
  padding: 10px;
}

footer > a {
  font-size: 14px;
  letter-spacing: 0.2px;
  color: var(--grey0);
  /* text-decoration: none; */
}



/* DESKTOP */
@media only screen and (min-width: 769px) {

  .react-horizontal-scrolling-menu--arrow-left, .react-horizontal-scrolling-menu--arrow-right {
    margin-top: 95px;
  }

  p {
    padding: 0;
    line-height: 30px;
  }

    
  ul {
    padding: 0;
  }


  main {
    margin-top: 60px;
    /* margin-top: 200px; */
  }

  h5 {
    padding: 0;
  }

}





/* DESKTOP */
@media only screen and (min-width: 1820px) {

  /* .rhs {
    display: block;
  } */

  /* .twitter-lower {
    display: none;
  } */

  /* .body-container {
    width: unset;
  }

  .body-home {
    width: unset;
  } */

}
